import { Link } from "react-router-dom";
import Container from "components/container";
import Heading from "components/heading";
import {
  breakpoint,
  textColor,
  BasicSection,
  GridPanel2,
  Divider,
} from "components/styles";
import styled from "@emotion/styled";

const FigureTitle = styled.h3({
  maxWidth: 560,
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  fontWeight: "bold",
  color: textColor,
});

const TrainMapContent = styled.div({
  position: "relative",
  paddingTop: 32,
  textAlign: "center",
});

const TrainMapImage = styled.img({
  width: "80%",
});

const ALMImage = styled.img({
  position: "absolute",
  left: 0,
  bottom: 0,
  height: "clamp(60px,15vw,120px)",
});

const TimelineContent = styled.div({
  paddingTop: 32,
});

const TimelineImage = styled.img({
  width: "100%",
});

const PartnerList = styled.ul({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  paddingTop: 32,
});

const PartnerItem = styled.li({
  listStyleType: "none",
});

const NexusImage = styled.img({
  height: 56,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: 45,
  },
});

const SOAImage = styled.img({
  height: 44,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: 36,
  },
});

const SPGlobalImage = styled.img({
  height: 28,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: 23.8,
  },
});

const CboeImage = styled.img({
  height: 28,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: 20.4,
  },
});

const PrmiaImage = styled.img({
  height: 48,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: 40.8,
  },
});

const RiskImage = styled.img({
  height: 24,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: 20.4,
  },
});

const OurCompany = () => (
  <main>
    <Heading isHome={false} name="Nexus Risk Management" title="Our Company" />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Nexus Risk Management</h2>
          <div>
            <p>
              Nexus has been providing risk management solutions to financial
              institutions for over twenty years. We provide dynamic hedging,
              asset liability management and risk optimization services to
              insurance companies globally and have jointly been managing assets
              for insurance companies, reinsurance companies and pension plans
              since 2005. Nexus designed, launched and managed a first of its
              kind fund with a large mutual fund company and Canadian bank in
              2013. Nexus has conducted years of research with Standard & Poor's
              and Cboe Global Markets and developed innovative risk management
              overlays and risk managed fund strategies. We have conducted
              internationally acclaimed training courses globally for over a
              decade with the Society of Actuaries, Cboe, Standard and Poor's,
              PRIMA, Risk Training and several actuarial organizations.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <div>
          <FigureTitle>
            Nexus has trained over 1000 risk professionals, regulators, rating
            agency analysts and actuaries globally
          </FigureTitle>
          <TrainMapContent>
            <TrainMapImage src="/static/media/train-map.jpg" alt="Train Map" />
            <ALMImage src="/static/media/alm.jpg" alt="ALM" />
          </TrainMapContent>
          <PartnerList>
            <PartnerItem>
              <Link to="/">
                <NexusImage
                  src="/static/media/nexus-dark.png"
                  alt="Nexus Risk Management"
                />
              </Link>
            </PartnerItem>
            <PartnerItem>
              <a
                href="https://www.soa.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SOAImage
                  src="/static/media/soa-dark.png"
                  alt="Society of Actuaries"
                />
              </a>
            </PartnerItem>
            <PartnerItem>
              <a
                href="https://www.spglobal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SPGlobalImage
                  src="/static/media/spglobal.png"
                  alt="S&P Global"
                />
              </a>
            </PartnerItem>
            <PartnerItem>
              <a
                href="https://www.cboe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CboeImage src="/static/media/cboe-dark.png" alt="Cboe" />
              </a>
            </PartnerItem>
            <PartnerItem>
              <a
                href="https://prmia.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrmiaImage src="/static/media/prmia.png" alt="Prmia" />
              </a>
            </PartnerItem>
            <PartnerItem>
              <a
                href="https://www.risk.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiskImage src="/static/media/risk.png" alt="Risk" />
              </a>
            </PartnerItem>
          </PartnerList>
        </div>
      </Container>
    </BasicSection>
    <Container>
      <Divider />
    </Container>
    <BasicSection>
      <Container>
        <div>
          <FigureTitle>
            Nexus has delivered advanced risk management solutions globally for
            over 20 years
          </FigureTitle>
          <TimelineContent>
            <TimelineImage src="/static/media/timeline.png" alt="Timeline" />
          </TimelineContent>
        </div>
      </Container>
    </BasicSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Our Team</h2>
          <div>
            <p>
              Nexus is made up of a core team of experienced traders, risk
              management experts, actuaries, quants and software engineers. We
              use the Nexus Risk Platform suite of asset liability management
              and dynamic hedging tools and utilities and have developed hedging
              strategies designed to provide protection against sudden large
              market drops using exchange traded, centrally cleared index
              options.
            </p>
            <p>
              Our hedging and sub-advisory trading services are executed through
              Nexus Risk Management LP, a wholly owned SEC registered investment
              advisor.
            </p>
            <p>
              In Canada, we offer combined asset management and ALM services to
              insurance companies, reinsurance companies and pension plans
              through a joint venture with CI Global Asset Management.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
  </main>
);

export default OurCompany;
