import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  GridPanel1,
  GridPanel2,
  GridPanel3,
  GridPanel4,
  CardItem,
  CardItemIndex,
  CardItemTitle,
  CenteredItem,
  BullImage,
  DotList,
  WhiteDot,
  BlueDot,
} from "components/styles";

const AssetLiabilityManagement = () => (
  <main>
    <Heading
      isHome={false}
      name="Nexus Risk Management"
      title={["Asset Liability", "Management"]}
    />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Take ALM to the Next Level</h2>
          <div>
            <p>
              Optimize performance on economic, accounting and regulatory bases.
            </p>
            <p>
              Manage risk on multiple bases simultaneously and evaluate
              trade-offs.
            </p>
          </div>
        </GridPanel2>
        <GridPanel3 even>
          <CardItem background="white">
            <CardItemIndex>I.</CardItemIndex>
            <CardItemTitle>Economic Reality</CardItemTitle>
            <p>
              The fundamental objective of every insurance company and pension
              plan is to ensure assets will be sufficient to payout liability
              cash flows under any interest rate scenario.
            </p>
          </CardItem>
          <CardItem background="white">
            <CardItemIndex>II.</CardItemIndex>
            <CardItemTitle>Profit & Loss</CardItemTitle>
            <p>
              While the long-term economic objective needs to be met, the
              immediate focus for many organizations is to maximize P&L and
              minimize volatility of P&L to interest rates and credit spreads.
            </p>
          </CardItem>
          <CardItem background="white">
            <CardItemIndex>III.</CardItemIndex>
            <CardItemTitle>Capital Efficiency</CardItemTitle>
            <p>
              Many insurance company and pension plan portfolios are suboptimal
              and capital inefficient resulting in lower P&L, lower investment
              income and lower capital ratios.
            </p>
          </CardItem>
        </GridPanel3>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>ALM as a Powerful Tool to Run the Business</h2>
          <div>
            <p>
              Many companies today are not being fairly compensated for risks
              they are assuming. Best practices that have emerged in the area of
              ALM have enabled insurance companies to use ALM as a strategic
              decision-making framework to add value, gain competitive advantage
              and achieve financial objectives. Nexus Risk Management works with
              insurance and reinsurance companies globally to implement best
              practice ALM frameworks, formulate effective ALM strategies and
              execute asset management within an ALM framework.
            </p>
            <p>
              Nexus Risk Management does a wide range of ALM related work for
              life insurance companies worldwide. Our clients include some of
              the largest multinational insurance and reinsurance companies.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection background="dark-blue">
      <Container>
        <GridPanel2 color="white">
          <div>
            <h2>Risk Optimization</h2>
            <p>
              Traditional portfolio optimization techniques do not necessarily
              add value and may not achieve the financial objectives.
            </p>
            <p>
              Nexus Risk Management has developed proprietary optimization
              algorithms that can help companies simultaneously maximize value
              and minimize risk.
            </p>
          </div>
          <CenteredItem>
            <BullImage src="/static/media/bull.png" alt="Bull" />
          </CenteredItem>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel1>
          <h2>Beyond Matching</h2>
          <div>
            <p>
              Insurance companies and pension plans have learned it is not
              enough to use simplistic approaches to manage risk. Interest rate
              risk is complex, has many dimensions and is not the same across
              economic, accounting and regulatory bases.
            </p>
          </div>
        </GridPanel1>
        <GridPanel4>
          <CardItem background="white">
            <DotList>
              <WhiteDot />
              <WhiteDot />
              <WhiteDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be perfectly duration matched and still have
              significant interest rate risk exposure.
            </p>
          </CardItem>
          <CardItem background="white">
            <DotList>
              <BlueDot />
              <WhiteDot />
              <WhiteDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be perfectly immunized across all dimensions of
              the interest rate risk exposure on an economic basis and still
              result in losses due to interest rates or swings in required
              capital.
            </p>
          </CardItem>
          <CardItem background="white">
            <DotList>
              <BlueDot />
              <BlueDot />
              <WhiteDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be perfectly immunized on an accounting or
              regulatory basis and still become insolvent.
            </p>
          </CardItem>
          <CardItem background="white">
            <DotList>
              <BlueDot />
              <BlueDot />
              <BlueDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be immunized across all dimensions and bases and
              be suboptimal.
            </p>
          </CardItem>
        </GridPanel4>
      </Container>
    </BasicSection>
  </main>
);

export default AssetLiabilityManagement;
