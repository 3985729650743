import { Link } from "react-router-dom";
import Container from "components/container";
import Heading from "components/heading";
import {
  whiteColor,
  darkBlueColor,
  BasicSection,
  ImageSection,
  LightBlueMask,
  GridPanel2,
  GridPanel3,
  CardItem,
  EvenIconContent,
  AutoIconContent,
} from "components/styles";
import styled from "@emotion/styled";
import { ReactComponent as PaperIcon } from "assets/icons/paper.svg";
import { ReactComponent as BrochureIcon } from "assets/icons/brochure.svg";
import { ReactComponent as SlideIcon } from "assets/icons/slide.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as ConferenceIcon } from "assets/icons/conference.svg";
import { ReactComponent as NewsroomIcon } from "assets/icons/newsroom.svg";
import { ReactComponent as EventIcon } from "assets/icons/event.svg";

const ProductItem = styled(CardItem)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 40,
});

const LinkButton = styled(Link)({
  display: "inline-block",
  minWidth: 160,
  marginTop: 24,
  padding: 16,
  textAlign: "center",
  lineHeight: 1.2,
  fontWeight: "bold",
  color: whiteColor,
  backgroundColor: darkBlueColor,
  textDecoration: "none",
});

const Home = () => (
  <main>
    <Heading
      isHome
      name="Nexus Risk Management"
      title={["Risk Efficient", "Performance"]}
    />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Risk Management Expertise</h2>
          <div>
            <p>
              Nexus has been providing risk management solutions to financial
              institutions for over twenty years. We provide dynamic hedging,
              asset liability management and risk optimization services to
              insurance companies globally and jointly manage assets for
              insurance companies, reinsurance companies and pension plans.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel3 even>
          <ProductItem>
            <div>
              <h2>Take ALM to the Next Level</h2>
              <p>
                Measure, manage and optimize performance on economic, accounting
                and regulatory bases.
              </p>
            </div>
            <LinkButton to="/asset-liability-management">
              Asset Liability Management
            </LinkButton>
          </ProductItem>
          <ProductItem>
            <div>
              <h2>Maximize Risk Efficiency</h2>
              <p>
                Many insurance company and pension plan portfolios are
                suboptimal and/or capital inefficient resulting in lower P&L,
                lower investment income and lower capital ratios.
              </p>
            </div>
            <LinkButton to="/asset-management">Asset Management</LinkButton>
          </ProductItem>
          <ProductItem>
            <div>
              <h2>Next Generation Hedging</h2>
              <p>
                Until now there have been 3 main hedging options:
                <br />
                1) Hold capital
                <br />
                2) Static hedge
                <br />
                3) Dynamic hedge
              </p>
            </div>
            <LinkButton to="/dynamic-hedging">Dynamic Hedging</LinkButton>
          </ProductItem>
        </GridPanel3>
      </Container>
    </BasicSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <div>
            <h2>Improve Risk Managed Fund Performance</h2>
            <LinkButton to="/risk-managed-strategies">Learn More</LinkButton>
          </div>
          <div>
            <p>
              The cost of protection provided by traditional risk managed funds
              has been high resulting in fund performance that has not been
              satisfactory to many investors. One reason is that using
              volatility as the primary means to provide protection is
              expensive. Nexus has developed rules-based risk management
              strategies that reshape the risk distribution more efficiently,
              giving up less market upside for the same amount of protection.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel3>
          <AutoIconContent>
            <PaperIcon />
            <h4>
              <Link
                to="/documents/Beyond Volatility - A New Way to Look at Risk Managed Index Strategy Performance.pdf"
                target="_blank"
              >
                Beyond Volatility: A New Way to Look at Risk Managed Index
                Strategy Performance
              </Link>
            </h4>
          </AutoIconContent>
          <AutoIconContent>
            <BrochureIcon />
            <h4>4-Page Brochure</h4>
          </AutoIconContent>
          <AutoIconContent>
            <SlideIcon />
            <h4>Slide Deck</h4>
          </AutoIconContent>
        </GridPanel3>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/machinery.jpg">
      <LightBlueMask>
        <Container>
          <GridPanel2 color="light-blue">
            <div>
              <h2>Reshape the Risk Distribution</h2>
              <LinkButton to="/sub-advisory-trading-services">
                Sub-Advisory
                <br />
                Trading Services
              </LinkButton>
            </div>
            <div>
              <p>
                Nexus has developed rules-based risk management overlay
                strategies that reshape the risk distribution of an underlying
                fund to reduce downside loss, reduce volatility giving up less
                market upside for the same amount of protection.
              </p>
            </div>
          </GridPanel2>
        </Container>
      </LightBlueMask>
    </ImageSection>
    <BasicSection>
      <Container>
        <GridPanel2 even>
          <EvenIconContent>
            <VideoIcon />
            <h4>
              Company Video
              <br />
              Nexus Risk Management
            </h4>
          </EvenIconContent>
          <EvenIconContent>
            <ConferenceIcon />
            <h4>
              <Link to="/events/2024/ebig">
                Equity-Based Insurance
                <br />
                Guarantee Conference
              </Link>
            </h4>
          </EvenIconContent>
          <EvenIconContent>
            <NewsroomIcon />
            <h4>Newsroom</h4>
          </EvenIconContent>
          <EvenIconContent>
            <EventIcon />
            <h4>
              <Link to="/training">Past Training Events</Link>
            </h4>
          </EvenIconContent>
        </GridPanel2>
      </Container>
    </BasicSection>
  </main>
);

export default Home;
