import Container from "components/container";
import Heading from "components/heading";
import { BasicSection, GridPanel1 } from "components/styles";
import styled from "@emotion/styled";

const ItemList = styled.ul({
  marginLeft: "1rem",
  paddingTop: "0.75rem",
});

const Privacy = () => (
  <main>
    <Heading isHome={false} name="Nexus Risk Management" title="Privacy" />
    <BasicSection>
      <Container>
        <GridPanel1>
          <div>
            <p>
              We at Nexus Risk Management are constantly striving to provide
              excellent service to you, our customers and business partners,
              while building a long-lasting relationship with you. To achieve
              these goals, we occasionally need information about you when you
              visit our site.
            </p>
            <p>
              First and foremost, Nexus Risk Management respects your right to
              privacy. When you visit Nexus Risk Management, you may be
              providing information to Nexus Risk Management on two different
              levels about your visit:
            </p>
            <ItemList>
              <li>Anonymous statistics collected as you browse the site,</li>
              <li>And personal information you knowingly give us.</li>
            </ItemList>
            <p>We want you to be aware of how we handle this information.</p>
          </div>
          <h2>Our Commitment to Your Privacy</h2>
          <div>
            <p>
              Nexus Risk Management would like to assure you that we do not:
            </p>
            <ItemList>
              <li>
                Collect personal information from you unless you provide it to
                us.
              </li>
              <li>
                Provide or sell the names and addresses of our customers [site
                users] to outside vendors.
              </li>
            </ItemList>
            <p>Nexus Risk Management does:</p>
            <ItemList>
              <li>
                Safeguard customers' information from unauthorized access.
              </li>
              <li>
                Collect, maintain and use customer information as necessary to
                provide the most helpful and targeted products and services to
                meet customers' needs (e.g. customer care or new products or
                services).
              </li>
              <li>
                Maintain confidentiality of customer information when
                undertaking projects with other companies.
              </li>
            </ItemList>
            <p>
              Nexus Risk Management has always valued your privacy, and we
              reinforce this belief throughout our organization. Your
              information will be protected as described unless the specific web
              page says otherwise. If you have further questions about our
              privacy policy, please contact us.
            </p>
          </div>
        </GridPanel1>
      </Container>
    </BasicSection>
  </main>
);

export default Privacy;
