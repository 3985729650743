import { Link } from "react-router-dom";
import Container from "components/container";
import Heading from "components/heading";
import {
  breakpoint,
  whiteColor,
  BasicSection,
  ImageSection,
  GridPanel2,
  GridPanel3,
  GridPanel4,
  AutoIconContent,
} from "components/styles";
import styled from "@emotion/styled";
import checkIcon from "assets/icons/check.svg";
import { ReactComponent as ConferenceIcon } from "assets/icons/conference.svg";
import { ReactComponent as CourseIcon } from "assets/icons/course.svg";
import { ReactComponent as ReportIcon } from "assets/icons/report.svg";

const EventPanel = styled(GridPanel4)({
  gridTemplateRows: "repeat(10,auto)",
  [`@media (max-width: ${breakpoint}px)`]: {
    gridTemplateRows: "repeat(39,auto)",
  },
  gridAutoFlow: "column",
  gap: 12,
  paddingTop: 32,
  lineHeight: 1.35,
  fontSize: "0.875rem",
  color: whiteColor,
  "& a": {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const CheckItem = styled.li({
  paddingLeft: 24,
  listStyle: "none",
  background: `url(${checkIcon}) no-repeat left 5px`,
});

const Training = () => (
  <main>
    <Heading
      isHome={false}
      name="Asset Liability Management"
      title="Training"
    />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Asset Liability Management Techniques And Practices</h2>
          <div>
            <p>
              This internationally acclaimed training course has been conducted
              globally for over a decade with the Society of Actuaries, Cboe,
              Standard and Poor's, PRIMA, Risk Training and several actuarial
              organizations.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel3>
          <AutoIconContent>
            <ConferenceIcon />
            <h4>Intensive Hands-On Training</h4>
          </AutoIconContent>
          <AutoIconContent>
            <CourseIcon />
            <h4>Content Rich Course Materials</h4>
          </AutoIconContent>
          <AutoIconContent>
            <ReportIcon />
            <h4>Utilities and Templates</h4>
          </AutoIconContent>
        </GridPanel3>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/city.jpg">
      <Container>
        <GridPanel2 color="white">
          <h2>Past Training Events</h2>
          <div>
            <p>
              This internationally acclaimed training course has been conducted
              globally for over a decade with the Society of Actuaries, Cboe,
              Standard and Poor's, PRIMA, Risk Training and several actuarial
              organizations.
            </p>
          </div>
        </GridPanel2>
        <EventPanel>
          <Link to="/brochures/Brochure Chicago 20130808.pdf" target="_blank">
            Chicago, United States
            <br />
            August 8-9, 2013
          </Link>
          <Link to="/brochures/Brochure Chicago 20130805.pdf" target="_blank">
            Chicago, United States
            <br />
            August 5-7, 2013
          </Link>
          <Link to="/brochures/Brochure Chicago 20121022.pdf" target="_blank">
            Chicago, United States
            <br />
            October 22-26, 2012
          </Link>
          <Link to="/brochures/Brochure Montreal 20120625.pdf" target="_blank">
            Montreal, Canada
            <br />
            June 25-27, 2012
          </Link>
          <Link to="/brochures/Brochure Chicago 20120426.pdf" target="_blank">
            Chicago, United States
            <br />
            April 26-27, 2012
          </Link>
          <Link to="/brochures/Brochure Chicago 20111003.pdf" target="_blank">
            Chicago, United States
            <br />
            October 3-7, 2011
          </Link>
          <Link to="/brochures/Brochure Brussels 20110912.pdf" target="_blank">
            Brussels, Belgium
            <br />
            September 12-14, 2011
          </Link>
          <Link to="/brochures/Brochure New York 20110801.pdf" target="_blank">
            New York, United States
            <br />
            August 1-3, 2011
          </Link>
          <Link to="/brochures/Brochure Seoul 20110623.pdf" target="_blank">
            Seoul, Korea
            <br />
            June 23-24, 2011
          </Link>
          <Link to="/brochures/Brochure Seoul 20110620.pdf" target="_blank">
            Seoul, Korea
            <br />
            June 20-22, 2011
          </Link>
          <Link to="/brochures/Brochure Hong Kong 20110516.pdf" target="_blank">
            Hong Kong
            <br />
            May 16-18, 2011
          </Link>
          <Link to="/brochures/Brochure New York 20110413.pdf" target="_blank">
            New York, United States
            <br />
            April 13-14, 2011
          </Link>
          <Link to="/brochures/Brochure Phoenix 20101207.pdf" target="_blank">
            Phoenix, United States
            <br />
            December 7-9, 2010
          </Link>
          <Link to="/brochures/Brochure London 20100914.pdf" target="_blank">
            London, UK
            <br />
            September 14-16, 2010
          </Link>
          <Link to="/brochures/Brochure New York 20100707.pdf" target="_blank">
            New York, United States
            <br />
            July 7-9, 2010
          </Link>
          <Link to="/brochures/Brochure Boston 20091014.pdf" target="_blank">
            Boston, United States
            <br />
            October 14, 2009
          </Link>
          <Link to="/brochures/Brochure Québec 20090817.pdf" target="_blank">
            Québec, Canada
            <br />
            August 17-19, 2009
          </Link>
          <Link to="/brochures/Brochure Toronto 20090611.pdf" target="_blank">
            Toronto, Canada
            <br />
            June 11, 2009
          </Link>
          <span>
            Bogota, Columbia (Fasecolda)
            <br />
            December 2008
          </span>
          <span>
            Boston, United States
            <br />
            October 2008
          </span>
          <span>
            Milan, Italy (Concentric)
            <br />
            September 2008
          </span>
          <Link to="/brochures/Brochure Toronto 20080609.pdf" target="_blank">
            Toronto, Canada
            <br />
            June 9-13, 2008
          </Link>
          <Link to="/brochures/Brochure Hong Kong 20080519.pdf" target="_blank">
            Hong Kong, P.R.C.
            <br />
            May 19-23, 2008
          </Link>
          <Link to="/brochures/Brochure Phoenix 20071203.pdf" target="_blank">
            Phoenix, United States
            <br />
            December 3-7, 2007
          </Link>
          <Link to="/brochures/Brochure Singapore 20070903.pdf" target="_blank">
            Singapore
            <br />
            September 3-7, 2007
          </Link>
          <span>
            Milan, Italy (Concentric)
            <br />
            June 2007
          </span>
          <Link to="/brochures/Brochure Prague 20070423.pdf" target="_blank">
            Prague, Czech Republic
            <br />
            April 23-27, 2007
          </Link>
          <span>
            Washington, US (World Bank)
            <br />
            December 2006
          </span>
          <Link to="/brochures/Brochure Taipei 20060731.pdf" target="_blank">
            Taipei, Taiwan
            <br />
            July 31-August 3, 2006
          </Link>
          <Link to="/brochures/Brochure NewYork 20060612.pdf" target="_blank">
            New York, United States
            <br />
            June 12-15, 2006
          </Link>
          <Link
            to="/brochures/Brochure Copenhagen 20060508.pdf"
            target="_blank"
          >
            Copenhagen, Denmark
            <br />
            May 8-11, 2006
          </Link>
          <span>
            Washington, US (World Bank)
            <br />
            April 2006
          </span>
          <Link to="/brochures/Brochure Hong Kong 20051108.pdf" target="_blank">
            Hong Kong, P.R.C.
            <br />
            November 8-10, 2005
          </Link>
          <Link to="/brochures/Brochure Brussels 20050516.pdf" target="_blank">
            Brussels, Belgium
            <br />
            May 16-19, 2005
          </Link>
          <Link to="/brochures/Brochure New York 20050404.pdf" target="_blank">
            New York, United States
            <br />
            April 4-7, 2005
          </Link>
          <Link to="/brochures/Brochure Québec 20040926.pdf" target="_blank">
            Québec City, Canada
            <br />
            September 26-30, 2004
          </Link>
          <Link to="/brochures/Brochure Tokyo 20040727.pdf" target="_blank">
            Tokyo, Japan
            <br />
            July 27-31, 2004
          </Link>
          <Link to="/brochures/Brochure London 20040620.pdf" target="_blank">
            London, United Kingdom
            <br />
            June 20-24, 2004
          </Link>
          <Link to="/brochures/Brochure Phoenix 20031207.pdf" target="_blank">
            Phoenix, United States
            <br />
            December 7-10, 2003
          </Link>
        </EventPanel>
      </Container>
    </ImageSection>
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>Who Should Attend</h2>
          <div>
            <p>
              <strong>
                Asset Liability Management Techniques and Practices
              </strong>{" "}
              has both reflected and influenced industry best practices,
              regulatory guidance and rating agency assessment. This course is a
              must for anyone responsible for risk management. Past course
              participants have immediately put to use the tools and techniques
              provided, used insights gained to prepare for reviews with rating
              agencies and regulators and have realized real bottom line results
              from being able to apply ALM at a strategic level. Course
              participants typically include:
            </p>
            <p>
              <strong>Senior management</strong> looking to gain a firm grasp of
              the financial risks facing their organizations, make more
              effective business decisions and use ALM strategically, beyond
              risk mitigation and compliance;
            </p>
            <p>
              <strong>Risk professionals</strong> already well versed in the
              underlying theory, but who want to gain further deep insight into
              the tools and techniques that are used in industry practice today;
            </p>
            <p>
              <strong>Professionals</strong> looking to enter into the field of
              ALM and who are willing to invest some additional time pre-course
              to learn the basic concepts;
            </p>
            <p>
              Asset managers and investment bankers wanting to provide sound ALM
              solutions to their clients;
            </p>
            <p>
              <strong>Actuaries</strong> looking to gain a more in-depth
              knowledge of this key area of practice;
            </p>
            <p>
              <strong>Board members</strong> looking to ensure they have the
              requisite knowledge of risk management in order to perform their
              fiduciary responsibilities.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>What Our Course Participants Gain</h2>
          <ul>
            <CheckItem>
              Execute mock trades and implement sophisticated ALM strategies
              against multiple dimensions of interest rate risk
            </CheckItem>
            <CheckItem>
              Learn the limitations and pitfalls of various risk metrics
            </CheckItem>
            <CheckItem>
              Aggregate and attribute economic capital by risk type and business
              entity
            </CheckItem>
            <CheckItem>
              Understand the practice of capital calculations under QIS5
            </CheckItem>
            <CheckItem>Pricing and hedging embedded guarantees</CheckItem>
            <CheckItem>
              Embedding ALM in decision making and passing the use test
            </CheckItem>
            <CheckItem>Participate in a mock ALM Committee meeting</CheckItem>
          </ul>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>Pre-Course</h2>
          <div>
            <p>
              Asset Liability Management Techniques and Practices delegates also
              receive Nexus' 5-week <Link to="/training">e-learning</Link> pre
              course.
            </p>
            <p>
              Pre-course material, assigned readings and a number of application
              exercises will be provided for the participant to cover outside of
              class time. Participants will also be trained to use several
              valuable utilities and templates that are yours to use.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
  </main>
);

export default Training;
