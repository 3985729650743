import { Link } from "react-router-dom";
import Container from "components/container";
import { textColor, whiteColor, darkBlueColor } from "components/styles";
import styled from "@emotion/styled";

const Root = styled.footer({
  "& a": {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  "& ul": {
    margin: 0,
    paddingLeft: 0,
  },
  "& li": {
    listStyleType: "none",
  },
});

const LinkSection = styled.section({
  backgroundColor: darkBlueColor,
});

const LinkPanel = styled.div({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: 24,
  paddingTop: 64,
  paddingBottom: 64,
  color: whiteColor,
});

const LinkTitle = styled.div({
  fontSize: "0.8125rem",
  fontWeight: "bold",
});

const LinkList = styled.ul({
  paddingTop: 4,
  fontSize: "0.75rem",
});

const LinkListItem = styled.li({
  paddingTop: 4,
  whiteSpace: "nowrap",
});

const LegalPanel = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  gap: 12,
  paddingTop: 12,
  paddingBottom: 12,
  fontSize: "0.75rem",
  fontWeight: "bold",
  color: textColor,
});

const LegalList = styled.ul({
  display: "flex",
  justifyContent: "space-between",
  gap: 16,
});

const linkData = [
  {
    title: "About Us",
    items: [
      {
        name: "Our Company",
        url: "/our-company",
      },
    ],
  },
  {
    title: "Events",
    items: [
      {
        name: "EBIG",
        url: "/events/2024/ebig",
      },
    ],
  },
  {
    title: "News and Resources",
    items: [
      {
        name: "News and Announcements",
      },
      {
        name: "White Papers, Articles and References",
      },
      {
        name: "Recent Projects",
      },
    ],
  },
  {
    title: "Contact Us",
    items: [
      {
        name: "Nexus Risk Management",
      },
      {
        name: "Exchange Tower",
      },
      {
        name: "130 King Street West",
      },
      {
        name: "Suite 1900",
      },
      {
        name: "Toronto, ON, M5X 1E3 Canada",
      },
      {
        name: "+1 416 593 9500",
      },
      {
        name: "inquiries@nexusrisk.com",
        url: "mailto:inquiries@nexusrisk.com",
      },
    ],
  },
];

const legalData = [
  {
    name: "Terms of Use",
    url: "/terms-of-use",
  },
  {
    name: "Privacy",
    url: "/privacy",
  },
  {
    name: "Legal",
    url: "/legal",
  },
];

const year = new Date().getFullYear();

const Footer = () => (
  <Root>
    <LinkSection>
      <Container>
        <LinkPanel>
          {linkData.map((x, i) => (
            <span key={`link-${i}`}>
              <LinkTitle>{x.title}</LinkTitle>
              <LinkList>
                {x.items.map((y, j) => (
                  <LinkListItem key={`link-${i}-${j}`}>
                    {y.url ? (
                      y.url.startsWith("/") ? (
                        <Link to={y.url}>{y.name}</Link>
                      ) : (
                        <a href={y.url}>{y.name}</a>
                      )
                    ) : (
                      y.name
                    )}
                  </LinkListItem>
                ))}
              </LinkList>
            </span>
          ))}
        </LinkPanel>
      </Container>
    </LinkSection>
    <section>
      <Container>
        <LegalPanel>
          <LegalList>
            {legalData.map((x, i) => (
              <li key={`legal-${i}`}>
                <Link to={x.url}>{x.name}</Link>
              </li>
            ))}
          </LegalList>
          <span>Copyright &copy; 2002-{year} Nexus Risk Management.</span>
        </LegalPanel>
      </Container>
    </section>
  </Root>
);

export default Footer;
