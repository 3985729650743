import { textColor } from "components/styles";
import styled from "@emotion/styled";

const Root = styled.main({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
});

const ErrorPanel = styled.span({
  paddingTop: 108,
  paddingBottom: 108,
  textAlign: "center",
  color: textColor,
});

const ErrorTitle = styled.div({
  fontSize: "3rem",
});

const ErrorContent = styled.div({
  paddingTop: 12,
  fontSize: "1.45rem",
});

const Error = () => (
  <Root>
    <ErrorPanel>
      <ErrorTitle>
        404 Error
        <br />
        Page Not Found
      </ErrorTitle>
      <ErrorContent>
        The page you requested may no longer exist or have been moved.
      </ErrorContent>
    </ErrorPanel>
  </Root>
);

export default Error;
