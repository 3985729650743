import { Fragment } from "react";
import Container from "components/container";
import {
  breakpoint,
  topbarHeight,
  menuHeight,
  whiteColor,
  darkBlueColor,
} from "components/styles";
import styled from "@emotion/styled";

type RootProps = {
  height: number;
};

const Root = styled("section", {
  shouldForwardProp: (propName: string) => propName !== "height",
})(({ height }: RootProps) => ({
  color: whiteColor,
  background: [
    `linear-gradient(${darkBlueColor}cc,${darkBlueColor}cc)`,
    `url(/static/media/lighthouse.jpg) no-repeat`,
  ].join(","),
  height: height - (topbarHeight + menuHeight),
  backgroundSize: "auto,100%",
  backgroundPosition: `left top,61.8% 50%`,
  "@media (max-width: 1920px)": {
    height: height - topbarHeight,
    backgroundSize: "auto,auto",
    backgroundPosition: `left top,61.8% 50%`,
  },
}));

const CompanyName = styled.div({
  paddingTop: 140,
  textTransform: "uppercase",
  fontSize: "1.6rem",
  [`@media (max-width: ${breakpoint}px)`]: {
    fontSize: "1.35rem",
  },
});

const Title = styled.h1({
  paddingTop: 24,
  lineHeight: 1.1,
  [`@media (max-width: ${breakpoint}px)`]: {
    fontSize: "3rem",
  },
});

type HeadingProps = {
  isHome: boolean;
  name: string;
  title: string | string[];
};

const Heading = ({ isHome, name, title }: HeadingProps) => (
  <Root height={isHome ? 720 : 512}>
    <Container>
      <CompanyName>{name}</CompanyName>
      <Title>
        {!Array.isArray(title)
          ? title
          : title.map((line, i) => (
              <Fragment key={i}>
                {line}
                {i < title.length - 1 && <br />}
              </Fragment>
            ))}
      </Title>
    </Container>
  </Root>
);

export default Heading;
