import Container from "components/container";
import Heading from "components/heading";
import { BasicSection, GridPanel1 } from "components/styles";

const year = new Date().getFullYear();

const TermsOfUse = () => (
  <main>
    <Heading isHome={false} name="Nexus Risk Management" title="Terms of Use" />
    <BasicSection>
      <Container>
        <GridPanel1>
          <div>
            <p>
              The content of this site, including but not limited to the text
              and images herein and their arrangement, are copyright &copy;
              2002-{year} by Nexus Risk Management. All Rights Reserved.
            </p>
            <p>
              Nexus Risk Management hereby authorizes you to copy and display
              the content herein, but for your personal, non-commercial use
              only. Any copy you make must include this copyright notice. If
              attribution to Nexus Risk Management is included, limited
              quotations from the content are hereby permitted. You may not copy
              or display for redistribution to third parties for commercial
              purposes any portion of the content without the prior written
              permission of Nexus Risk Management.
            </p>
          </div>
          <h2>No Implied Licenses</h2>
          <div>
            <p>
              Nothing contained herein shall be construed as conferring by
              implication, estoppel, or otherwise any license or right under any
              patent, trademark, copyright (except as expressly provided above),
              or proprietary rights of Nexus Risk Management or of any third
              party.
            </p>
          </div>
          <h2>The Content</h2>
          <div>
            <p>
              Nexus Risk Management makes no representations about the
              suitability of the content of this site for any purpose. All
              content is provided &quot;as is&quot; without any warranty of any
              kind. Nexus Risk Management hereby disclaims all warranties and
              conditions with regard to the content, including but not limited
              to all implied warranties and conditions of merchantability,
              fitness for a particular purpose, title, and non-infringement. In
              no event shall Nexus Risk Management be liable for any special,
              indirect, exemplary, or consequential damages or any damages
              whatsoever, including but not limited to loss of use, data, or
              profits, without regard to the form of any action, including but
              not limited to contract, negligence, or other tortious actions,
              arising out of or in connection with the use, copying, or display
              of the content.
            </p>
            <p>
              Although Nexus Risk Management believes the content to be
              accurate, complete, and current, Nexus Risk Management makes no
              warranty as to the accuracy, completeness or currency of the
              content. It is your responsibility to verify any information
              before relying on it. The content of this site may include
              technical inaccuracies or typographical errors. From time to time
              changes are made to the content herein. Nexus Risk Management may
              make changes in the products and/or the services described herein
              at any time.
            </p>
            <p>
              Any opinions expressed herein are solely those of the creators of
              the material and are not the opinions of Nexus Risk Management,
              its subsidiaries, or its affiliates, or the management of those
              entities.
            </p>
          </div>
          <h3>Information Provided by You</h3>
          <div>
            <p>
              Any information provided by you to Nexus Risk Management,
              including but not limited to feedback, data, questions, comments,
              suggestions, or the like, shall be deemed to be non-confidential;
              Nexus Risk Management shall have no obligation of any kind with
              respect to such information and shall be free to reproduce, use,
              disclose, and distribute the information to others without
              limitation. Additionally, Nexus Risk Management shall be free to
              use any ideas, concepts, know-how or techniques contained in such
              information for any purpose whatsoever, including but not limited
              to developing, manufacturing, and marketing products incorporating
              such information.
            </p>
          </div>
          <h2>Trademarks</h2>
          <div>
            <p>
              Nexus Risk Management and the Nexus Risk Management logo are
              trademarks of Nexus Risk Management. All other trademarks are
              either owned by Nexus Risk Management or by other companies.
            </p>
          </div>
        </GridPanel1>
      </Container>
    </BasicSection>
  </main>
);

export default TermsOfUse;
