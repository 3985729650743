import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  GridPanel2,
  GridPanel3,
  CardItem,
} from "components/styles";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as AdviceIcon } from "assets/icons/advice.svg";
import { ReactComponent as TrainingIcon } from "assets/icons/training.svg";

const DynamicHedging = () => (
  <main>
    <Heading
      isHome={false}
      name="Nexus Risk Management"
      title="Dynamic Hedging"
    />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Next Generation Hedging</h2>
          <div>
            <p>
              Until now there have been 3 main hedging options: 1) Hold capital,
              2) Static hedge, or 3) Dynamic hedge.
            </p>
            <p>
              Next generation hedging allows companies to reduce hedging costs
              and maximize financial objectives.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>Dynamic Hedging Implementation</h2>
          <div>
            <p>
              Nexus Risk Management helps clients develop best practices dynamic
              hedging capabilities. We believe that companies that are serious
              about offering products with equity-based insurance guarantees
              should have the in-house expertise to manage this business.
            </p>
            <p>
              To help insurers develop the requisite expertise and execute their
              own dynamic hedging programs, we provide a full suite of
              customized dynamic hedging tools and applications, expert advice
              and in-house training.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel3 even>
          <CardItem>
            <EditIcon />
            <h2>Customized Software</h2>
            <p>
              Software and tools customized to the liabilities and integrated
              with existing systems. Source code and user documentation
              provided.
            </p>
          </CardItem>
          <CardItem>
            <AdviceIcon />
            <h2>Expert Advice</h2>
            <p>
              Hedge strategies, product design, risk modeling and analysis,
              implementation of trading and monitoring process.
            </p>
          </CardItem>
          <CardItem>
            <TrainingIcon />
            <h2>In-house Training</h2>
            <p>
              Customized in-house training programs for insurers, reinsurers,
              rating agencies and regulators.
            </p>
          </CardItem>
        </GridPanel3>
      </Container>
    </BasicSection>
  </main>
);

export default DynamicHedging;
