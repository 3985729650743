import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  ImageSection,
  LightBlueMask,
  GridPanel1,
  GridPanel2,
} from "components/styles";
import styled from "@emotion/styled";
import { ReactComponent as CheckIcon } from "assets/icons/check-variant.svg";

const EfficiencyPanel = styled(GridPanel2)({
  paddingTop: 32,
});

const EfficiencyItem = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const EfficiencyItemIcon = styled(CheckIcon)({
  fontSize: "3.5rem",
});

const EfficiencyItemText = styled.h4({
  textTransform: "uppercase",
});

const RiskManagedStrategies = () => (
  <main>
    <Heading
      isHome={false}
      name="Nexus Risk Management"
      title={["Risk Managed", "Strategies"]}
    />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel1>
          <div>
            <h2>Reshaping the Risk Distribution</h2>
            <p>
              Nexus has developed rules-based risk management overlay strategies
              that reshape the risk distribution of an underlying fund to reduce
              downside loss, reduce volatility giving up less market upside for
              the same amount of protection.
            </p>
          </div>
        </GridPanel1>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/machinery.jpg">
      <LightBlueMask>
        <Container>
          <GridPanel2 color="light-blue">
            <h2>Improved Cost / Protection Efficiency</h2>
            <div>
              <p>
                Nexus risk managed strategies are customizable and can be
                designed for risk managed funds or as an overlay on a fund or
                portfolio.
              </p>
            </div>
          </GridPanel2>
          <EfficiencyPanel even color="light-blue">
            <EfficiencyItem>
              <EfficiencyItemIcon />
              <EfficiencyItemText>Market Participation</EfficiencyItemText>
            </EfficiencyItem>
            <EfficiencyItem>
              <EfficiencyItemIcon />
              <EfficiencyItemText>Protected Leverage</EfficiencyItemText>
            </EfficiencyItem>
            <EfficiencyItem>
              <EfficiencyItemIcon />
              <EfficiencyItemText>Downside Protection</EfficiencyItemText>
            </EfficiencyItem>
            <EfficiencyItem>
              <EfficiencyItemIcon />
              <EfficiencyItemText>Lower Volatility</EfficiencyItemText>
            </EfficiencyItem>
          </EfficiencyPanel>
        </Container>
      </LightBlueMask>
    </ImageSection>
    <BasicSection>
      <Container>
        <GridPanel1>
          <div>
            <h2>Downside Protection</h2>
            <p>
              Protected leverage is provided using a rules-based risk management
              strategy that increases market exposure to the S&P 500 Index above
              or below 100%, providing both leverage and downside protection
              based on market conditions.
            </p>
          </div>
        </GridPanel1>
      </Container>
    </BasicSection>
  </main>
);

export default RiskManagedStrategies;
