import { useState, useLayoutEffect } from "react";

export const useScrollY = () => {
  const [scrollY, setScrollY] = useState(0);

  useLayoutEffect(() => {
    const updateScrollY = () => setScrollY(window.pageYOffset);
    updateScrollY();
    window.addEventListener("scroll", updateScrollY, { passive: true });
    return () => window.removeEventListener("scroll", updateScrollY);
  }, []);

  return scrollY;
};
