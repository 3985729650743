import type { Property } from "csstype";
import styled from "@emotion/styled";

export const breakpoint = 928;
export const topbarHeight = 80;
export const menuHeight = 32;

export const textColor = "#253b53";
export const whiteColor = "white";
export const grayColor = "gray";
export const lightGrayColor = "#f0f0f0";
export const lightBlueColor = "#e6edf3";
export const darkBlueColor = "#344961";
export const maskColor = "#b2c2d299";

export const borderColor = "#ccc";

type BasicSectionProps = {
  padding?: "compact";
  gap?: "compact";
  background?: "light-blue" | "dark-blue";
};

export const BasicSection = styled("section", {
  shouldForwardProp: (propName: string) =>
    propName !== "padding" && propName !== "gap" && propName !== "background",
})(({ padding, gap, background }: BasicSectionProps) => ({
  paddingTop: padding === "compact" ? 32 : 64,
  paddingBottom: padding === "compact" ? 32 : 64,
  ...(background === "light-blue"
    ? { backgroundColor: lightBlueColor }
    : background === "dark-blue"
    ? { backgroundColor: darkBlueColor }
    : {}),
  "& > *": {
    display: "flex",
    flexDirection: "column" as Property.FlexDirection,
    rowGap: gap === "compact" ? 32 : 64,
  },
}));

type ImageSectionProps = {
  image: string;
};

export const ImageSection = styled("section", {
  shouldForwardProp: (propName: string) => propName !== "image",
})(({ image }: ImageSectionProps) => ({
  paddingTop: 64,
  paddingBottom: 64,
  background: [
    `linear-gradient(${darkBlueColor}cc,${darkBlueColor}cc)`,
    `url(${image}) no-repeat center center / cover`,
  ].join(","),
}));

export const LightBlueMask = styled.div({
  paddingTop: 64,
  paddingBottom: 64,
  backgroundColor: maskColor,
});

export const GridPanel1 = styled.div({
  display: "grid",
  gap: 24,
  color: textColor,
});

type GridPanel2Props = {
  even?: boolean;
  color?: "white" | "light-blue";
};

export const GridPanel2 = styled(GridPanel1, {
  shouldForwardProp: (propName: string) =>
    propName !== "even" && propName !== "color",
})(({ even, color }: GridPanel2Props) => ({
  gridTemplateColumns: even ? "repeat(2,1fr)" : "0.382fr 0.618fr",
  [`@media (max-width: ${breakpoint}px)`]: {
    gridTemplateColumns: "1fr",
  },
  ...(color === "white"
    ? { color: whiteColor }
    : color === "light-blue"
    ? { color: lightBlueColor }
    : {}),
}));

type GridPanel3Props = {
  even?: boolean;
};

export const GridPanel3 = styled(GridPanel1, {
  shouldForwardProp: (propName: string) => propName !== "even",
})(({ even }: GridPanel3Props) => ({
  gridTemplateColumns: even ? "repeat(3,1fr)" : "repeat(3,auto)",
  [`@media (max-width: ${breakpoint}px)`]: {
    gridTemplateColumns: "1fr",
  },
  ...(even ? {} : { justifyContent: "space-between" }),
}));

export const GridPanel4 = styled(GridPanel1)({
  gridTemplateColumns: "repeat(4,1fr)",
  [`@media (max-width: ${breakpoint}px)`]: {
    gridTemplateColumns: "1fr",
  },
});

type CardItemProps = {
  background?: "white";
};

export const CardItem = styled("div", {
  shouldForwardProp: (propName: string) => propName !== "background",
})(({ background }: CardItemProps) => ({
  padding: "48px 24px",
  boxShadow: `4px 4px 8px ${borderColor}`,
  ...(background === "white" ? { backgroundColor: whiteColor } : {}),
}));

export const CenteredItem = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const BullImage = styled.img({
  width: 320,
});

const IconContent = styled.div({
  gap: 16,
  "& a": {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export const EvenIconContent = styled(IconContent)({
  display: "flex",
  "& > :last-child": {
    flex: 1,
  },
});

export const AutoIconContent = styled(IconContent)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  "& > *": {
    maxWidth: 240,
  },
});

export const CardItemIndex = styled.div({
  fontSize: "3rem",
  fontWeight: "bold",
});

export const CardItemTitle = styled.h2({
  textTransform: "uppercase",
});

export const DotList = styled.div({
  textAlign: "center",
});

export const WhiteDot = styled.span({
  display: "inline-block",
  width: 24,
  height: 24,
  marginLeft: 8,
  marginRight: 8,
  border: `1px solid ${textColor}`,
  borderRadius: "50%",
});

export const BlueDot = styled(WhiteDot)({
  backgroundColor: darkBlueColor,
});

export const Divider = styled.hr({
  boxSizing: "border-box",
  height: 1,
  border: 0,
  borderTop: `1px solid ${borderColor}`,
});
