import { ReactNode } from "react";
import styled from "@emotion/styled";

const Root = styled.div({
  maxWidth: 960,
  margin: "0 auto",
  paddingLeft: 16,
  paddingRight: 16,
});

type ContainerProps = {
  children: ReactNode | ReactNode[];
};

const Container = ({ children }: ContainerProps) => <Root>{children}</Root>;

export default Container;
