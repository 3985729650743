import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  ImageSection,
  LightBlueMask,
  GridPanel2,
  EvenIconContent,
  CenteredItem,
  BullImage,
} from "components/styles";
import { ReactComponent as PortfolioIcon } from "assets/icons/portfolio.svg";
import { ReactComponent as FrameworkIcon } from "assets/icons/framework.svg";
import { ReactComponent as SoftwareIcon } from "assets/icons/software.svg";
import { ReactComponent as ResultIcon } from "assets/icons/result.svg";
import { ReactComponent as AttributionIcon } from "assets/icons/attribution.svg";
import { ReactComponent as StrategyIcon } from "assets/icons/strategy.svg";
import { ReactComponent as OptimizationIcon } from "assets/icons/optimization.svg";
import { ReactComponent as ExpertiseIcon } from "assets/icons/expertise.svg";

const ConsultingAndImplementation = () => (
  <main>
    <Heading
      isHome={false}
      name="Asset Liability Management"
      title={["Consulting and", "Implementation"]}
    />
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>Our Services</h2>
          <div>
            <p>
              Nexus Risk Management does a wide range of ALM related work for
              life insurance companies worldwide. Our clients include some of
              the largest multinational insurance and reinsurance companies.
            </p>
          </div>
        </GridPanel2>
        <GridPanel2 even>
          <EvenIconContent>
            <PortfolioIcon />
            <h4>ALM Review and Independent Quantification of Risk Exposure</h4>
          </EvenIconContent>
          <EvenIconContent>
            <FrameworkIcon />
            <h4>ALM Framework Implementation</h4>
          </EvenIconContent>
          <EvenIconContent>
            <SoftwareIcon />
            <h4>ALM Software, Tools and Utilities</h4>
          </EvenIconContent>
          <EvenIconContent>
            <ResultIcon />
            <h4>Projecting financial result risk exposure</h4>
          </EvenIconContent>
          <EvenIconContent>
            <AttributionIcon />
            <h4>Attribution Analysis</h4>
          </EvenIconContent>
          <EvenIconContent>
            <StrategyIcon />
            <h4>Formulating ALM Strategies</h4>
          </EvenIconContent>
          <EvenIconContent>
            <OptimizationIcon />
            <h4>ALM Study and SAA Optimization</h4>
          </EvenIconContent>
          <EvenIconContent>
            <ExpertiseIcon />
            <h4>Develop In-House Capabilities and Expertise</h4>
          </EvenIconContent>
        </GridPanel2>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/watch.jpg">
      <LightBlueMask>
        <Container>
          <GridPanel2 color="light-blue">
            <h2>Nexus Risk Platform</h2>
            <div>
              <p>
                We use Nexus Risk Platform ALM, a powerful software tool to
                manage the multiple dimensions of the risks associated with the
                asset and liability cash flows and perform optimization analyses
                based on our clients' financial goals. The impact of proposed
                trades or strategies on the risk position is viewed
                instantaneously. Advanced reporting and analytics provide
                insights and valuable decision support.
              </p>
            </div>
          </GridPanel2>
        </Container>
      </LightBlueMask>
    </ImageSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <div>
            <h2>Portfolio Optimization and Asset Management</h2>
            <p>
              On top of the services provided with the ALM Service Package Nexus
              Risk Management also provides risk optimization and asset
              management services.
            </p>
          </div>
          <CenteredItem>
            <BullImage src="/static/media/bull.png" alt="Bull" />
          </CenteredItem>
        </GridPanel2>
      </Container>
    </BasicSection>
  </main>
);

export default ConsultingAndImplementation;
