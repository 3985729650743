import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import type { Property } from "csstype";
import Container from "components/container";
import { useScrollY } from "hooks/scroll-y";
import { useClickAway } from "hooks/click-away";
import {
  breakpoint,
  topbarHeight,
  menuHeight,
  textColor,
  whiteColor,
  lightGrayColor,
  lightBlueColor,
  darkBlueColor,
} from "components/styles";
import styled from "@emotion/styled";
import { ReactComponent as HambergerIcon } from "assets/icons/hamberger.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "assets/icons/chevron-up.svg";

type ThemeProps = {
  dark: boolean;
};

const Dummy = styled("div", {
  shouldForwardProp: (propName: string) => propName !== "dark",
})(({ dark }: ThemeProps) => ({
  display: dark ? "none" : "block",
  height: topbarHeight + menuHeight,
  [`@media (max-width: ${breakpoint}px)`]: {
    height: topbarHeight,
  },
}));

const Root = styled("header", {
  shouldForwardProp: (propName: string) => propName !== "dark",
})(({ dark }: ThemeProps) => ({
  position: (dark ? "static" : "fixed") as Property.Position,
  left: 0,
  right: 0,
  zIndex: 10,
  color: dark ? whiteColor : textColor,
  background: dark
    ? [
        `linear-gradient(${darkBlueColor}cc,${darkBlueColor}cc)`,
        `url(/static/media/lighthouse.jpg) no-repeat center top`,
      ].join(",")
    : whiteColor,
  backgroundSize: "auto,100%",
  [`@media (max-width: ${breakpoint}px)`]: {
    backgroundSize: "auto,auto",
  },
}));

const Topbar = styled.section({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: topbarHeight,
});

const LogoImage = styled.img({
  height: 80,
});

const LinkButton = styled(Link, {
  shouldForwardProp: (propName: string) => propName !== "dark",
})(({ dark }: ThemeProps) => ({
  display: "inline-block",
  [`@media (max-width: ${breakpoint}px)`]: {
    display: "none",
  },
  padding: "8px 24px",
  fontSize: "0.875rem",
  fontWeight: "bold",
  color: dark ? textColor : whiteColor,
  backgroundColor: dark ? lightBlueColor : darkBlueColor,
  textTransform: "uppercase" as Property.TextTransform,
  textDecoration: "none",
}));

const Hamberger = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 2,
  fontSize: "1.4rem",
  textTransform: "uppercase",
  cursor: "pointer",
});

const MainMenuPanel = styled("nav", {
  shouldForwardProp: (propName: string) => propName !== "dark",
})(({ dark }: ThemeProps) => ({
  [`@media (max-width: ${breakpoint}px)`]: {
    display: "none",
  },
  backgroundColor: dark ? "transparent" : darkBlueColor,
}));

const MainMenu = styled.ul({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  height: menuHeight,
  fontSize: "0.875rem",
  fontWeight: "bold",
  color: whiteColor,
});

const MainMenuItem = styled.div({
  display: "flex",
  alignItems: "center",
});

const MainSubmenuItemText = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

const MainSubmenu = styled.div({
  position: "absolute",
  left: -16,
  top: 32,
  zIndex: 20,
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "12px 16px",
  backgroundColor: darkBlueColor,
});

type DropdownMenuPanelProps = {
  open: boolean;
  dark: boolean;
};

const DropdownMenuPanel = styled("div", {
  shouldForwardProp: (propName: string) =>
    propName !== "open" && propName !== "dark",
})(({ open, dark }: DropdownMenuPanelProps) => ({
  display: "none",
  [`@media (max-width: ${breakpoint}px)`]: {
    display: "block",
  },
  position: "relative" as Property.Position,
  padding: "8px 16px",
  backgroundColor: open
    ? dark
      ? darkBlueColor
      : lightGrayColor
    : "transparent",
}));

const DropdownMenu = styled("ul", {
  shouldForwardProp: (propName: string) => propName !== "dark",
})(({ dark }: ThemeProps) => ({
  position: "absolute" as Property.Position,
  right: 0,
  top: 48,
  zIndex: 20,
  display: "flex",
  flexDirection: "column" as Property.FlexDirection,
  alignItems: "flex-end",
  gap: 16,
  padding: 16,
  backgroundColor: dark ? darkBlueColor : lightGrayColor,
}));

const DropdownSubmenuItemText = styled(MainSubmenuItemText)({
  fontSize: "0.875rem",
});

const MenuItem = styled.li({
  listStyleType: "none",
  whiteSpace: "nowrap",
  "& a": {
    textDecoration: "none",
  },
});

type SubmenuIconProps = {
  size: number;
};

const SubmenuIcon = styled("span", {
  shouldForwardProp: (propName: string) => propName !== "size",
})(({ size }: SubmenuIconProps) => ({
  display: "flex",
  fontSize: size,
  cursor: "pointer",
}));

const menuData = [
  {
    name: "Asset Liability Management",
    url: "/asset-liability-management",
    children: [
      {
        name: "Consulting and Implementation",
        url: "/consulting-and-implementation",
      },
      {
        name: "Risk Optimization",
        url: "/risk-optimization",
      },
      {
        name: "Execution and Outsourcing",
        url: "/execution-and-outsourcing",
      },
      {
        name: "Training",
        url: "/training",
      },
    ],
  },
  {
    name: "Asset Management",
    url: "/asset-management",
  },
  {
    name: "Dynamic Hedging",
    url: "/dynamic-hedging",
  },
  {
    name: "Sub-Advisory Trading Services",
    url: "/sub-advisory-trading-services",
  },
  {
    name: "Risk Managed Strategies",
    url: "/risk-managed-strategies",
  },
  {
    name: "About Us",
    url: "/our-company",
    disabled: true,
  },
];

const isHoverSupported = window.matchMedia("(hover: hover)").matches;

const Header = () => {
  const rootRef = useRef(null);
  const scrollY = useScrollY();
  const root = rootRef.current as HTMLElement | null;
  const dark = !root || scrollY <= root.clientHeight;
  const logoImage = dark
    ? "/static/media/nexus-light.png"
    : "/static/media/nexus-dark.png";

  const [almSubmenuOpen, setALMSubmenuOpen] = useState(false);
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const openDesktopALMSubmenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (isHoverSupported) {
      setALMSubmenuOpen(true);
    }
  };

  const closeDesktopALMSubmenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (isHoverSupported) {
      setALMSubmenuOpen(false);
    }
  };

  const closeALMSubmenu = (e: MouseEvent | React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setALMSubmenuOpen(false);
  };

  const toggleALMSubmenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setALMSubmenuOpen((open) => !open);
  };

  const almSubmenuRef = useRef(null);
  useClickAway(almSubmenuRef, closeALMSubmenu);

  const closeDropdownMenu = (e: MouseEvent | React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDropdownMenuOpen(false);
  };

  const toggleDropdownMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDropdownMenuOpen((open) => !open);
  };

  const dropdownMenuPanelRef = useRef(null);
  useClickAway(dropdownMenuPanelRef, closeDropdownMenu);

  return (
    <>
      <Root ref={rootRef} dark={dark}>
        <Container>
          <Topbar>
            <Link to="/">
              <LogoImage src={logoImage} alt="Logo" />
            </Link>
            <LinkButton to="/our-company" dark={dark}>
              About Us
            </LinkButton>
            <DropdownMenuPanel
              ref={dropdownMenuPanelRef}
              dark={dark}
              open={dropdownMenuOpen}
            >
              <Hamberger onClick={toggleDropdownMenu}>
                <HambergerIcon />
                <span>Menu</span>
              </Hamberger>
              {dropdownMenuOpen && (
                <DropdownMenu dark={dark}>
                  {menuData.map((x, i) => (
                    <React.Fragment key={`dropdown-menu-${i}`}>
                      <MenuItem onClick={closeDropdownMenu}>
                        <Link to={x.url}>{x.name}</Link>
                      </MenuItem>
                      {x.children &&
                        x.children.map((y, j) => (
                          <MenuItem
                            onClick={closeDropdownMenu}
                            key={`dropdown-menu-${i}-${j}`}
                          >
                            <DropdownSubmenuItemText>
                              <Link to={y.url}>{y.name}</Link>
                              <SubmenuIcon size={20}>
                                <ChevronLeftIcon />
                              </SubmenuIcon>
                            </DropdownSubmenuItemText>
                          </MenuItem>
                        ))}
                    </React.Fragment>
                  ))}
                </DropdownMenu>
              )}
            </DropdownMenuPanel>
          </Topbar>
        </Container>
        <MainMenuPanel dark={dark}>
          <Container>
            <MainMenu>
              {menuData.map(
                (x, i) =>
                  !x.disabled && (
                    <MainMenuItem
                      onMouseLeave={x.children && closeDesktopALMSubmenu}
                      key={`alm-menu-${i}`}
                    >
                      <MenuItem>
                        <MainSubmenuItemText>
                          <Link
                            to={x.url}
                            onMouseEnter={x.children && openDesktopALMSubmenu}
                          >
                            {x.name}
                          </Link>
                          {x.children && (
                            <SubmenuIcon size={22} onClick={toggleALMSubmenu}>
                              {almSubmenuOpen ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )}
                            </SubmenuIcon>
                          )}
                        </MainSubmenuItemText>
                      </MenuItem>
                      {almSubmenuOpen && x.children && (
                        <MainSubmenu ref={almSubmenuRef}>
                          {x.children.map((y, j) => (
                            <MenuItem
                              onClick={closeALMSubmenu}
                              key={`alm-menu-${i}-${j}`}
                            >
                              <Link to={y.url}>{y.name}</Link>
                            </MenuItem>
                          ))}
                        </MainSubmenu>
                      )}
                    </MainMenuItem>
                  )
              )}
            </MainMenu>
          </Container>
        </MainMenuPanel>
      </Root>
      <Dummy dark={dark} />
    </>
  );
};

export default Header;
