import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  GridPanel1,
  GridPanel2,
  GridPanel3,
  GridPanel4,
  CardItem,
  CardItemIndex,
  CardItemTitle,
  CenteredItem,
  BullImage,
  DotList,
  WhiteDot,
  BlueDot,
} from "components/styles";

const AssetManagement = () => (
  <main>
    <Heading
      isHome={false}
      name="Nexus Risk Management"
      title="Asset Management"
    />
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>Maximize Risk and Capital Efficiency</h2>
          <div>
            <p>
              Many insurance company and pension plan portfolios are suboptimal
              and/or capital inefficient resulting in lower P&L, lower
              investment income and lower capital ratios.
            </p>
          </div>
        </GridPanel2>
        <GridPanel3 even>
          <CardItem>
            <CardItemIndex>I.</CardItemIndex>
            <CardItemTitle>Strategic Asset Allocation</CardItemTitle>
            <p>
              Optimize the SAA by maximizing the expected value or distribution
              of outcomes such as portfolio return, P&L and/or capital ratio for
              a given level of risk measured on economic, accounting and
              regulatory bases.
            </p>
          </CardItem>
          <CardItem>
            <CardItemIndex>II.</CardItemIndex>
            <CardItemTitle>Cash Flow Profile</CardItemTitle>
            <p>
              Optimize the asset cash flows relative to the liabilities for the
              desired financial objective(s) on economic, accounting and
              regulatory bases based on the current or anticipated rate
              environment.
            </p>
          </CardItem>
          <CardItem>
            <CardItemIndex>III.</CardItemIndex>
            <CardItemTitle>Credit Spreads</CardItemTitle>
            <p>
              Optimize the desired financial objectives based on credit spreads
              by term, rating and associated risk charges.
            </p>
          </CardItem>
        </GridPanel3>
      </Container>
    </BasicSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>Asset Management within an ALM Framework</h2>
          <div>
            <p>
              Through strategic partnerships we provide integrated asset
              management and ALM services for a competitive fee. By managing the
              assets directly against the liability cash flows and focusing on
              the financial objectives of our clients we add significant value
              over the traditional asset management approach. Our services allow
              our clients to free up resources devoted to ALM while ensuring
              that ALM best practices are being performed.
            </p>
            <p>
              Nexus Risk Management does a wide range of ALM related work for
              life insurance companies worldwide. Our clients include some of
              the largest multinational insurance and reinsurance companies.
            </p>
          </div>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection background="dark-blue">
      <Container>
        <GridPanel2 color="white">
          <div>
            <h2>Integrated Services</h2>
            <p>
              Traditional portfolio optimization techniques do not necessarily
              add value and may not achieve the financial objectives.
            </p>
            <p>
              Nexus Risk Management has developed proprietary optimization
              algorithms that can help companies simultaneously maximize value
              and minimize risk.
            </p>
          </div>
          <CenteredItem>
            <BullImage src="/static/media/bull.png" alt="Bull" />
          </CenteredItem>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel1>
          <h2>Beyond Matching</h2>
          <div>
            <p>
              Insurance companies and pension plans have learned it is not
              enough to use simplistic approaches to manage risk. Interest rate
              risk is complex, has many dimensions and is not the same across
              economic, accounting and regulatory bases.
            </p>
          </div>
        </GridPanel1>
        <GridPanel4>
          <CardItem>
            <DotList>
              <WhiteDot />
              <WhiteDot />
              <WhiteDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be perfectly duration matched and still have
              significant interest rate risk exposure.
            </p>
          </CardItem>
          <CardItem>
            <DotList>
              <BlueDot />
              <WhiteDot />
              <WhiteDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be perfectly immunized across all dimensions of
              the interest rate risk exposure on an economic basis and still
              result in losses due to interest rates or swings in required
              capital.
            </p>
          </CardItem>
          <CardItem>
            <DotList>
              <BlueDot />
              <BlueDot />
              <WhiteDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be perfectly immunized on an accounting or
              regulatory basis and still become insolvent.
            </p>
          </CardItem>
          <CardItem>
            <DotList>
              <BlueDot />
              <BlueDot />
              <BlueDot />
              <WhiteDot />
            </DotList>
            <p>
              A portfolio can be immunized across all dimensions and bases and
              be suboptimal.
            </p>
          </CardItem>
        </GridPanel4>
      </Container>
    </BasicSection>
  </main>
);

export default AssetManagement;
