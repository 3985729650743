import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  ImageSection,
  LightBlueMask,
  GridPanel2,
  GridPanel3,
  CardItem,
  CardItemIndex,
  CardItemTitle,
} from "components/styles";

const SubAdvisoryTradingServices = () => (
  <main>
    <Heading
      isHome={false}
      name="Nexus Risk Management"
      title={["Sub-Advisory", "Trading Services"]}
    />
    <BasicSection>
      <Container>
        <GridPanel3 even>
          <CardItem>
            <CardItemIndex>I.</CardItemIndex>
            <CardItemTitle>Risk Managed Fund Strategies</CardItemTitle>
            <p>
              Beyond volatility, Nexus risk managed strategies focus on
              providing market exposure while significantly reducing volatility
              and drawdowns.
            </p>
          </CardItem>
          <CardItem>
            <CardItemIndex>II.</CardItemIndex>
            <CardItemTitle>Overlay Strategies</CardItemTitle>
            <p>
              Nexus risk management overlay strategies can executed on actively
              managed funds and portfolios to provide downside protection by
              reshaping the risk distribution.
            </p>
          </CardItem>
          <CardItem>
            <CardItemIndex>III.</CardItemIndex>
            <CardItemTitle>Hedging</CardItemTitle>
            <p>
              Nexus provides both traditional dynamic hedging and next
              generation hedging solutions.
            </p>
          </CardItem>
        </GridPanel3>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/machinery.jpg">
      <LightBlueMask>
        <Container>
          <GridPanel2 color="light-blue">
            <h2>Improved Hedge Efficiency</h2>
            <div>
              <p>
                Sub-Advisory Trading Services are provided through Nexus Risk
                Management LP, a registered investment advisor, registered with
                the Securities and Exchange Commission ("SEC").
              </p>
            </div>
          </GridPanel2>
        </Container>
      </LightBlueMask>
    </ImageSection>
  </main>
);

export default SubAdvisoryTradingServices;
