import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  ImageSection,
  LightBlueMask,
  GridPanel2,
  GridPanel3,
  CardItem,
  CenteredItem,
  BullImage,
} from "components/styles";
import { ReactComponent as FrameworkIcon } from "assets/icons/framework.svg";
import { ReactComponent as StrategyIcon } from "assets/icons/strategy.svg";
import { ReactComponent as MonitorIcon } from "assets/icons/monitor.svg";

const ExecutionAndOutsourcing = () => (
  <main>
    <Heading
      isHome={false}
      name="Asset Liability Management"
      title={["Execution and", "Outsourcing"]}
    />
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <h2>ALM Service Package</h2>
          <div>
            <p>
              Nexus Risk Management launched its ALM Service Package in response
              to the demand from companies who are not able to allocate
              sufficient resources to the ALM function or stay on top of
              industry best practices. The ALM Service Package is a complete
              solution that allows companies to retain control of the risk
              management of their business by gaining access to sophisticated
              tools, expertise, training and assistance needed to perform ALM on
              an on-going basis.
            </p>
          </div>
        </GridPanel2>
        <GridPanel3 even>
          <CardItem background="white">
            <FrameworkIcon />
            <h2>Framework Implementation</h2>
            <p>
              Implementation of ALM at a strategic decision making framework to
              achieve financial objectives on economic, accounting and
              regulatory capital bases.
            </p>
          </CardItem>
          <CardItem background="white">
            <StrategyIcon />
            <h2>Executing ALM Strategies</h2>
            <p>
              Formulation and execution of ALM strategies to gain competitive
              advantage and maximize shareholder value.
            </p>
          </CardItem>
          <CardItem background="white">
            <MonitorIcon />
            <h2>ALM Monitoring and Reporting</h2>
            <p>
              Independent monitoring of the multiple dimensions of interest rate
              and market risk exposure, deep insights and valuable decision
              support.
            </p>
          </CardItem>
        </GridPanel3>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/watch.jpg">
      <LightBlueMask>
        <Container>
          <GridPanel2 color="light-blue">
            <h2>Nexus Risk Platform</h2>
            <div>
              <p>
                We use Nexus Risk Platform ALM, a powerful software tool to
                manage the multiple dimensions of the risks associated with the
                asset and liability cash flows and perform optimization analyses
                based on our clients' financial goals. The impact of proposed
                trades or strategies on the risk position is viewed
                instantaneously. Advanced reporting and analytics provide
                insights and valuable decision support.
              </p>
            </div>
          </GridPanel2>
        </Container>
      </LightBlueMask>
    </ImageSection>
    <BasicSection background="light-blue">
      <Container>
        <GridPanel2>
          <div>
            <h2>Portfolio Optimization and Asset Management</h2>
            <p>
              On top of the services provided with the ALM Service Package Nexus
              Risk Management also provides risk optimization and asset
              management services.
            </p>
          </div>
          <CenteredItem>
            <BullImage src="/static/media/bull.png" alt="Bull" />
          </CenteredItem>
        </GridPanel2>
      </Container>
    </BasicSection>
  </main>
);

export default ExecutionAndOutsourcing;
