import Container from "components/container";
import Heading from "components/heading";
import {
  BasicSection,
  ImageSection,
  LightBlueMask,
  GridPanel1,
  GridPanel2,
  GridPanel3,
  AutoIconContent,
  CenteredItem,
  BullImage,
} from "components/styles";
import { ReactComponent as OptimizationIcon } from "assets/icons/optimization.svg";
import { ReactComponent as PortfolioIcon } from "assets/icons/portfolio.svg";
import { ReactComponent as SlideIcon } from "assets/icons/slide.svg";

const RiskOptimization = () => (
  <main>
    <Heading
      isHome={false}
      name="Asset Liability Management"
      title="Risk Optimization"
    />
    <BasicSection>
      <Container>
        <GridPanel2>
          <h2>Next Generation Optimization</h2>
          <div>
            <p>
              Many insurance company and pension plan portfolios are suboptimal.
              This means that for a given level of risk the financial objective
              is not maximized.
            </p>
          </div>
        </GridPanel2>
        <GridPanel1>
          <h3>Beyond Investment Objectives</h3>
          <div>
            <p>
              Maximizing expected portfolio return and achieving investment
              objectives do not necessarily achieve financial objectives and
              only look at one dimension.
            </p>
            <p>Maximizing asset-only return not enough.</p>
            <p>
              Maximizing portfolio yield per se likewise will not ensure the
              financial objectives are maximized.
            </p>
          </div>
          <h3>Beyond Asset Mix</h3>
          <div>
            <p>
              The first and most important investment decision is the strategic
              asset allocation. Traditional optimization techniques maximize the
              expected return of the portfolio based on standard deviation.
              However, the return that will be realized will generally be
              anything but the expected return - it could be higher, lower or
              even negative. Having a proper understanding of the distribution
              of portfolio returns is necessary, beyond just the first two
              statistical moments. Furthermore, the distribution and any
              "optimal" asset mix are highly sensitive to the assumptions used.
            </p>
            <p>
              Once the asset mix has been optimized, the cash flow profile and
              credit spreads need to be optimized. All three of these
              optimization analyses (asset mix, cash flow profile and credit
              spreads) are performed on three bases (economic, accounting and
              regulatory). Trade-offs are evaluated between risk and reward and
              between economic value, accounting results and required capital.
              An optimal portfolio can then be selected that is both risk and
              capital efficient AND maximizes the financial objective.
            </p>
          </div>
          <h3>Our Approach</h3>
          <div>
            <p>
              Before we perform any work, we sit down with senior management to
              understand the business issues, risk appetite and discuss the
              financial objectives.
            </p>
            <p>
              Risk limits need to be informed by the risk efficiency fronter
              developed by performing a risk optimization analysis on all 3
              bases in order to understand the trade-offs between economic,
              accounting and regulatory capital.
            </p>
          </div>
        </GridPanel1>
      </Container>
    </BasicSection>
    <BasicSection background="dark-blue">
      <Container>
        <GridPanel2 color="white">
          <div>
            <h2>Maximize Value for a Given Level of Risk</h2>
            <p>
              Traditional portfolio optimization techniques do not necessarily
              add value and may not achieve the financial objectives.
            </p>
            <p>
              Nexus Risk Management has developed proprietary optimization
              algorithms that can help companies simultaneously maximize value
              and minimize risk on multiple bases.
            </p>
          </div>
          <CenteredItem>
            <BullImage src="/static/media/bull.png" alt="Bull" />
          </CenteredItem>
        </GridPanel2>
      </Container>
    </BasicSection>
    <BasicSection>
      <Container>
        <GridPanel3>
          <AutoIconContent>
            <OptimizationIcon />
            <h4>Strategic Asset Allocation Optimization Analysis</h4>
          </AutoIconContent>
          <AutoIconContent>
            <PortfolioIcon />
            <h4>Cash Flow Profile Optimization Analysis</h4>
          </AutoIconContent>
          <AutoIconContent>
            <SlideIcon />
            <h4>Credit Spread Optimization Analysis</h4>
          </AutoIconContent>
        </GridPanel3>
      </Container>
    </BasicSection>
    <ImageSection image="/static/media/watch.jpg">
      <LightBlueMask>
        <Container>
          <GridPanel2 color="light-blue">
            <h2>Nexus Risk Platform</h2>
            <div>
              <p>
                We use Nexus Risk Platform ALM, a powerful software tool to
                manage the multiple dimensions of the risks associated with the
                asset and liability cash flows and perform optimization analyses
                based on our clients' financial goals. The impact of proposed
                trades or strategies on the risk position is viewed
                instantaneously. Advanced reporting and analytics provide
                insights and valuable decision support.
              </p>
            </div>
          </GridPanel2>
        </Container>
      </LightBlueMask>
    </ImageSection>
  </main>
);

export default RiskOptimization;
