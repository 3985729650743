import Container from "components/container";
import Heading from "components/heading";
import { BasicSection, GridPanel1 } from "components/styles";
import styled from "@emotion/styled";

const ItemList = styled.ul({
  marginLeft: "1rem",
  paddingTop: "0.75rem",
});

const Legal = () => (
  <main>
    <Heading isHome={false} name="Nexus Risk Management" title="Legal" />
    <BasicSection>
      <Container>
        <GridPanel1>
          <h2>Information this Site Gathers/Tracks</h2>
          <h3>Statistics about your visit</h3>
          <div>
            <p>
              As you browse the site, Nexus Risk Management's computers collect
              information about your visit, not about you personally. Via web
              server logs and browser cookie files, we monitor statistics such
              as:
            </p>
            <ItemList>
              <li>How many people visit our site</li>
              <li>Which pages people visit on our site</li>
              <li>
                From which domains our visitors come (e.g., &quot;aol.com&quot;)
              </li>
              <li>Which browsers people use to visit our site</li>
            </ItemList>
            <p>
              While we gather this information, none of it is associated with
              you as an individual. We use these statistics to improve our web
              site, to monitor its performance, and to make it easier for you
              and other visitors to use.
            </p>
          </div>
          <h3>Organizational and personal information you knowingly give us</h3>
          <div>
            <p>
              Within the Nexus Risk Management web site, you may be asked to
              give us personal or organizational information when you ask a
              question that requires a response, request rate information
              online, or order a service online. This information may range from
              your generic zip code, to your specific phone number, account
              number, a credit card number, e-mail address, organization URL,
              business description, business graphics, or mailing address.
            </p>
          </div>
          <h2>Use of the Information this Site Gathers/Tracks</h2>
          <h3>Your IP Address and Host Name</h3>
          <div>
            <p>
              Nexus Risk Management's web site logs Host Names and IP addresses
              (when the Host Name is not available) for systems administration
              and security purposes. These logs are monitored and analyzed to
              maintain systems and information security and allow us to
              constantly improve the value and the availability of the web
              content.
            </p>
          </div>
          <h3>Nexus Risk Management Registration Forms</h3>
          <div>
            <p>
              We encourage all visitors to register with Nexus Risk Management,
              although registration is not required, except for access to our
              premium content areas. The registration forms require users to
              give us accurate contact information (like name and e-mail
              address) and preferences that help us better serve you. Your name
              and e-mail address are used to send you information about our
              company, product updates, special offers, and newsletters. You may
              change your preferences at any time, or remove your name entirely
              from our user registry.
            </p>
          </div>
          <h3>Order Forms and Enrollment Forms</h3>
          <div>
            <p>
              Our order forms securely collect visitor's contact information and
              financial information (like your credit card number and other
              billing information). Contact information on the order form is
              used for order fulfillment and may be shared with our Partners or
              Resellers if that is necessary to fulfill your request. The
              information that we require on our order form is only used to
              process your order quickly and accurately. Your contact
              information will only be used to send you the product that you
              purchase and your billing information will only be used to bill
              you for that product. During the purchasing process, you may be
              given the opportunity to sign up for future e-mailings regarding
              our products, services, and promotions. If you sign up here, Nexus
              Risk Management will share your contact information with other
              Nexus Risk Management companies only, not with other companies.
            </p>
            <p>
              As Nexus Risk Management's online systems become more
              sophisticated, you may also be given the option of registering a
              profile with Nexus Risk Management. This would store your name,
              purchase history, and billing information to make it easier for
              you to shop and purchase products/services from us in the future.
              Once profiling is available, you may opt out of this feature at
              any time by changing/canceling your profile online.
            </p>
          </div>
          <h3>Cookies</h3>
          <div>
            <p>
              A cookie is a small, encrypted data string our server writes to
              your hard drive that contains your unique Nexus Risk Management
              User ID. We use cookies to deliver web content specific to your
              interests and to control access to Nexus Risk Management premium
              content. A cookie cannot be used to access or otherwise compromise
              the data on your hard drive. You can choose to change your browser
              settings to disable cookies if you do not want us to establish and
              maintain a unique Nexus Risk Management User ID. Please be aware
              that cookies may be required to complete certain functions on
              Nexus Risk Management.
            </p>
          </div>
          <h2>Sharing of the Information this Site Gathers/Tracks</h2>
          <div>
            <p>
              The information you submit on the order form is never shared with
              any third parties unless it is necessary to complete your request.
              Also, as required by law, Nexus Risk Management will not share
              customer proprietary network information with other Nexus Risk
              Management companies, unless you give Nexus Risk Management
              permission to do so (either online, or via phone or mail).
            </p>
          </div>
          <h2>Information Security</h2>
          <div>
            <p>
              We have security measures in place to protect the loss, misuse and
              alteration of information under our control. When you place an
              order, the information you submit is encrypted using the Secure
              Sockets Layer (SSL) protocol.
            </p>
          </div>
          <h2>Links to Other Sites</h2>
          <div>
            <p>
              The Nexus Risk Management web site contains links to other web
              sites. Please be aware that our privacy policy does not apply to
              these sites.
            </p>
          </div>
          <h2>Opt-out Policy</h2>
          <div>
            <p>
              Our site provides users the opportunity to opt-out of receiving
              communications from us and our Partners at the point where we
              request information about the visitor. Visitors who wish to remove
              their information from our database to not receive future
              communications or to no longer receive our service can contact us.
            </p>
          </div>
          <h2>Correcting/Update Policy</h2>
          <div>
            <p>
              If you would like to correct and/or update your Preferred Listing
              information, please contact us at{" "}
              <a href="mailto:inquiries@nexusrisk.com">
                inquiries@nexusrisk.com
              </a>
              .
            </p>
          </div>
        </GridPanel1>
      </Container>
    </BasicSection>
  </main>
);

export default Legal;
