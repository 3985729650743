import { useEffect, RefObject } from "react";

export const useClickAway = (
  ref: RefObject<HTMLElement | null>,
  onClickAway: (e: MouseEvent) => void
) => {
  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        onClickAway(e);
      }
    };

    document.addEventListener("mousedown", handleClickAway);
    return () => document.removeEventListener("mousedown", handleClickAway);
  }, [ref, onClickAway]);
};
