import { type ReactNode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "components/scroll-to-top";
import Header from "components/header";
import Footer from "components/footer";
import Home from "views/home";
import Error from "views/error";
import AssetLiabilityManagement from "views/asset-liability-management";
import AssetManagement from "views/asset-management";
import ExecutionAndOutsourcing from "views/execution-and-outsourcing";
import RiskOptimization from "views/risk-optimization";
import ConsultingAndImplementation from "views/consulting-and-implementation";
import Training from "views/training";
import DynamicHedging from "views/dynamic-hedging";
import SubAdvisoryTradingServices from "views/sub-advisory-trading-services";
import RiskManagedStrategies from "views/risk-managed-strategies";
import OurCompany from "views/our-company";
import EBIG2024 from "views/events/ebig-2024";
import TermsOfUse from "views/terms-of-use";
import Privacy from "views/privacy";
import Legal from "views/legal";
import { Global } from "@emotion/react";
import styled from "@emotion/styled";

const styles = {
  body: {
    margin: "0 auto",
    fontFamily: [
      "'Helvetica Neue'",
      "Arial",
      "-apple-system",
      "BlinkMacSystemFont",
      "'Segoe UI'",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "'Fira Sans'",
      "'Droid Sans'",
      "sans-serif",
    ].join(","),
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    WebkitTapHighlightColor: "transparent",
  },
  code: {
    fontFamily: [
      "source-code-pro",
      "Menlo",
      "Monaco",
      "Consolas",
      "'Courier New'",
      "monospace",
    ].join(","),
  },
  "h1,h2,h3,h4,p,ul": {
    margin: 0,
    padding: 0,
    lineHeight: 1.5,
  },
  "h1,h2,h3,h4": {
    fontWeight: "normal",
  },
  h1: {
    fontSize: "3.75rem",
  },
  h2: {
    fontSize: "1.75rem",
  },
  h3: {
    fontSize: "1.5rem",
  },
  h4: {
    fontSize: "1.25rem",
  },
  "* + h2,* + p": {
    paddingTop: "1.5rem",
  },
  "p + p": {
    paddingTop: "0.75rem",
  },
  a: {
    color: "inherit",
  },
};

const Root = styled.div({
  display: "flex",
  flexFlow: "column",
  minHeight: "100vh",
});

type Page = { url: string; content: ReactNode; standard: boolean };

const pages: Page[] = [
  {
    url: "/",
    content: <Home />,
    standard: true,
  },
  {
    url: "/asset-liability-management",
    content: <AssetLiabilityManagement />,
    standard: true,
  },
  {
    url: "/execution-and-outsourcing",
    content: <ExecutionAndOutsourcing />,
    standard: true,
  },
  {
    url: "/risk-optimization",
    content: <RiskOptimization />,
    standard: true,
  },
  {
    url: "/consulting-and-implementation",
    content: <ConsultingAndImplementation />,
    standard: true,
  },
  {
    url: "/training",
    content: <Training />,
    standard: true,
  },
  {
    url: "/asset-management",
    content: <AssetManagement />,
    standard: true,
  },
  {
    url: "/dynamic-hedging",
    content: <DynamicHedging />,
    standard: true,
  },
  {
    url: "/sub-advisory-trading-services",
    content: <SubAdvisoryTradingServices />,
    standard: true,
  },
  {
    url: "/risk-managed-strategies",
    content: <RiskManagedStrategies />,
    standard: true,
  },
  {
    url: "/our-company",
    content: <OurCompany />,
    standard: true,
  },
  {
    url: "/events/2024/ebig",
    content: <EBIG2024 />,
    standard: false,
  },
  {
    url: "/terms-of-use",
    content: <TermsOfUse />,
    standard: true,
  },
  {
    url: "/privacy",
    content: <Privacy />,
    standard: true,
  },
  {
    url: "/legal",
    content: <Legal />,
    standard: true,
  },
  {
    url: "*",
    content: <Error />,
    standard: true,
  },
];

const createElement = (page: Page) => (
  <>
    {page.standard && <Header />}
    {page.content}
    {page.standard && <Footer />}
  </>
);

const App = () => (
  <HelmetProvider>
    <Global styles={styles} />
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Root>
        <Routes>
          {pages.map((page, i) => (
            <Route
              path={page.url}
              element={createElement(page)}
              key={`page-${i}`}
            />
          ))}
        </Routes>
      </Root>
    </BrowserRouter>
  </HelmetProvider>
);

export default App;
